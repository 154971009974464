<template>
  <model title="Loading" tag="加载中">
    <p>加载中界面有组件和方法调用两种方式。</p>
    <h3>组件方式</h3>
    <p>组件方式可以在局部加载时使用</p>
    <lh-loading>加载中...</lh-loading>
    <lh-collapse>
      <div slot="title">示例代码</div>
      <lh-code-editor v-model="code1" :readonly="true"/>
    </lh-collapse>
    <h3>调用方式</h3>
    <p>通过调用方法函数的方式可以在全局加载时使用</p>
    <lh-button @click="loading">点击加载</lh-button>
    <div>
      <lh-collapse>
        <div slot="title">示例代码</div>
        <lh-code-editor v-model="code2" :readonly="true"/>
      </lh-collapse>
    </div>
    <h3>属性方法参考表</h3>
    <h4>组件属性参考表：</h4>
    <lh-table>
      <lh-tr>
        <lh-th>参数</lh-th>
        <lh-th>说明</lh-th>
        <lh-th>类型</lh-th>
        <lh-th>可选值</lh-th>
        <lh-th>默认值</lh-th>
      </lh-tr>
      <lh-tr>
        <lh-td>size</lh-td>
        <lh-td>组件大小</lh-td>
        <lh-td>String</lh-td>
        <lh-td>-</lh-td>
        <lh-td>100px</lh-td>
      </lh-tr>
      <lh-tr>
        <lh-td>padding</lh-td>
        <lh-td>组件内边距</lh-td>
        <lh-td>String</lh-td>
        <lh-td>-</lh-td>
        <lh-td>20px</lh-td>
      </lh-tr>
      <lh-tr>
        <lh-td>color</lh-td>
        <lh-td>组件颜色</lh-td>
        <lh-td>String</lh-td>
        <lh-td>-</lh-td>
        <lh-td>#409EFF</lh-td>
      </lh-tr>
    </lh-table>
    <h4>函数方法参考表：</h4>
    <p>lhLoading 对象有两个函数方法，一个是 open ，一个是 close ，分别用于打开和关闭加载界面。</p>
    <lh-table>
      <lh-tr>
        <lh-th>方法名</lh-th>
        <lh-th>说明</lh-th>
        <lh-th>参数</lh-th>
        <lh-th>返回值</lh-th>
      </lh-tr>
      <lh-tr>
        <lh-td>open</lh-td>
        <lh-td>打开加载界面</lh-td>
        <lh-td>一个参数，加载界面的提示文字</lh-td>
        <lh-td>-</lh-td>
      </lh-tr>
      <lh-tr>
        <lh-td>close</lh-td>
        <lh-td>关闭加载界面</lh-td>
        <lh-td>-</lh-td>
        <lh-td>-</lh-td>
      </lh-tr>
    </lh-table>
  </model>
</template>

<script>
import Model from '../../components/Model.vue'
export default {
  components: { Model },
  data(){
    return{
      code1:
        "<template>\n"+
        "  <lh-loading>加载中...</lh-loading>\n"+
        "</template>",
      code2:
        "<template>\n"+
        "  <lh-button @click=\"loading\">点击加载</lh-button>\n"+
        "</template>\n\n"+
        "<script>\n"+
        "export default {\n"+
        "  methods:{\n"+
        "    loading:function(){\n"+
        "      this.$lhLoading.open(\"加载中...\");\n"+
        "      setTimeout(()=>{\n"+
        "        this.$lhLoading.close();\n"+
        "      },2000)\n"+
        "    }\n"+
        "  }\n"+
        "}\n"+
        "</scr"+"ipt>"
    }
  },
  methods:{
    loading:function(){
      this.$lhLoading.open("加载中...");
      setTimeout(()=>{
        this.$lhLoading.close();
      },2000)
    }
  }
}
</script>

<style>

</style>